/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentCompany, fetchCurrentCompanyCredits, fetchCurrentCompanyRequests } from "../../actions/company.actions";
import { useParams } from "react-router-dom";
import { DashboardTemplate } from "../../templates/DashboardTemplate"
import { Message, MessageHeader, Segment } from 'semantic-ui-react'
import CompanySideMenu from "../../components/tabs/company/SideMenu";
import CompanyGeneralInfo from "../../components/tabs/company/GeneralInfo";
import CompanyAdvisorsTab from "../../components/tabs/company/AdvisorsTab";
import CompanyEstateSnapshotTab from "../../components/tabs/company/EstateSnapshotTab";
import CompanyCreditsGeneral from "../../components/tabs/company/CreditsGeneral";
import CompanyPurchasedCredits from "../../components/tabs/company/PurchasedCredits";
import CompanyRequestedCredits from "../../components/tabs/company/RequestedCredits";
import IntegrationSalesforce from "../../components/tabs/company/IntegrationSalesforce";
import { Page404 } from "../404";
import CompanyAdvisorGroups from "../../components/tabs/company/advisor-group";

const CompanyProfile = () => {
    const params = useParams();
    const { companyId } = params;
    const [activeMenu, setActiveMenu] = useState('general')
    const dispatch = useDispatch();
    const [finishLoader, setFinishLoader] = useState(false)

    useEffect(() => {
      // console.log({CompanyProfile: companyId})
      dispatch(fetchCurrentCompany(companyId));
      dispatch(fetchCurrentCompanyCredits(companyId, 'all'));
      dispatch(fetchCurrentCompanyRequests(companyId));

      setTimeout(() => {
        setFinishLoader(true)
      },1000)
    }, []);

    const companyProfile = useSelector((state) => state.currentCompany);

    if(Object.keys(companyProfile).length < 1 && finishLoader){
      return <Page404 />
    }

    return (
        <DashboardTemplate scope='companies' permission="companies:company_view">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridAutoRows: '1fr',
              columnGap: '1em',
            }}
          >
            <CompanySideMenu activeMenu={activeMenu} changeMenu={setActiveMenu} />
            <div>
              {!companyProfile?.superAdmin && <>
                {/* <Segment style={{ margin: 0, marginBottom: "10px" }}> */}

                <Message negative>
                  <MessageHeader>Currently, there is no Super Admin. Please assign this role to someone.</MessageHeader>
                  <p style={{cursor: "pointer"}} onClick={() => setActiveMenu("advisors")}>We are going to assign an advisor.</p>
                </Message>
                {/* </Segment> */}
              </>}
              <Segment style={{ margin: 0 }}>

                <CompanyGeneralInfo activeMenu={activeMenu} />
                <CompanyAdvisorGroups activeMenu={activeMenu} />
                <CompanyAdvisorsTab activeTab={activeMenu} />
                <CompanyEstateSnapshotTab activeTab={activeMenu} />
                <CompanyCreditsGeneral activeMenu={activeMenu} />
                <CompanyPurchasedCredits activeMenu={activeMenu} />
                <CompanyRequestedCredits activeMenu={activeMenu} />

                <IntegrationSalesforce activeMenu={activeMenu} />
              </Segment>

            </div>
          </div>
        </DashboardTemplate>
    )
}

export default CompanyProfile