const ALL_AREAS = {
    'estate': 'Estate Planning',
    'auto': 'Auto',
    'crossborder': 'Cross Border',
    'disability': 'Disability',
    'home': 'Home',
    'medical': 'Medical',
    'identity': 'Identity',
    'student': 'Student Loan',
    'expense': 'Expense',
    'fulltax': 'Tax Planning',
    'ltc': 'LTC',
    'mortgage': 'Mortgage',
    'life': 'Life Planning',
    'divorce': 'Divorce Planning',
    'property': 'Property',
    'creditor': 'Creditor',
    'elder': 'Elder care',
};

// From https://stackoverflow.com/a/12646864
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export const addAdvisorBuilder = (email, fullName, nameCompany, role,phone, areas, disabledFeatures, licenseState) => {
    let password = [];
    const upperAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerAlphabet = upperAlphabet.toLowerCase();
    const numbers = '0123456789';
    const specialChars = '#$%&!@';

    for (let i = 0; i < 4; i++) {
        password.push(lowerAlphabet[Math.floor(Math.random() * lowerAlphabet.length)]);
    }

    for (let i = 0; i < 2; i++) {
        password.push(upperAlphabet[Math.floor(Math.random() * upperAlphabet.length)]);
    }

    password.push(numbers[Math.floor(Math.random() * numbers.length)]);
    password.push(specialChars[Math.floor(Math.random() * specialChars.length)]);

    shuffleArray(password);

    //console.log(role);

    const payload = {
        email,
        fullName,
        password: password.join(''),
        nameCompany,
        enterpriseRole: role,
        hasEnabled2FA: 'false',
        isOnFreeTrialPeriod: (licenseState === 'trial') + '',
        phone,
        prefilledClients: 'true',
        disabledFeatures
    };

    //console.log({email, fullName, nameCompany, role,phone, areas, disabledFeatures, licenseState})

    if(licenseState === "licensed" && areas.length < 1){
        console.log(`${fullName} is ${licenseState} and not has any areas, we can't add props "hide"`)
    }else {
        const areasIds = Object.keys(ALL_AREAS);
        for (const area of areasIds) {
            const capitalized = capitalizeArea(area);
            payload[`hide${capitalized}`] = !areas.includes(area) //* Trial or License are the same validations
            //payload[`hide${capitalized}`] = (licenseState === 'trial' && !areas.includes(area)) + '';
        }
    }


    return payload;
}

const capitalizeArea = (area) => {
    return area[0].toUpperCase() + area.substring(1, area.length);
}