import { useEffect, useState } from 'react';
import { responseError } from '../../../../helpers';
import { advisorAPI } from '../../../../apis/AdvisorsAPI';
import { useSelector } from 'react-redux';
import { Button, Icon, Label, Modal } from 'semantic-ui-react';
import { areasSimpleComplexNameMatch } from '../../../../helpers/nameAreas';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const CompanyAdvisorGroups = ({ activeMenu }) => {
  const [advisorGroups, setAdvisorGroups] = useState([]);
  const companyProfile = useSelector((state) => state.currentCompany);

  const getAdvisorGroups = async () => {
    try {
      const { data } = await advisorAPI.get('/advisor-group/by-company', {
        params: {
          companyId: companyProfile?._id,
          withAdvisors: true,
        },
      });

      setAdvisorGroups(data.data);
      console.log({ data });
    } catch (error) {
      responseError(error, undefined, 'getAdvisorGroups');
    }
  };

  useEffect(() => {
    if (companyProfile?._id) {
      getAdvisorGroups();
    }

    // eslint-disable-next-line
  }, [companyProfile?._id]);

  return (
    <>
      {activeMenu === 'advisorGroup' && (
        <>
          <h1>Advisor Groups ({advisorGroups.length})</h1>
          <div className="flex-gap-5" style={{ gap: '15px' }}>
            {advisorGroups.map((advisorGroup, index) => (
              <div key={index}>
                <CardAdvisorGroup advisorGroup={advisorGroup} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const CardAdvisorGroup = ({ advisorGroup }) => {
  const history = useHistory();
  const [showAreas, setShowAreas] = useState(false);
  const [showSnapshots, setShowSnapshots] = useState(false);
  const [showSimulators, setShowSimulators] = useState(false);
  const [showTaskManager, setShowTaskManager] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showInsigths, setShowInsigths] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const[showAdvisors, setShowAdvisors] = useState(false);
  return (
    <>
      <div className="card-border-line">
        <h2>{advisorGroup.name}</h2>

        <div className="grid_fp">
          <div>
            <div>
              <strong>Areas:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowAreas(!showAreas)}>{showAreas ? 'Hide' : 'Show'}</Button>
            {showAreas && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.areas.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>

          <div>
            <div>
              <strong>Snapshots:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowSnapshots(!showSnapshots)}>{showSnapshots ? 'Hide' : 'Show'}</Button>
            {showSnapshots && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.snapshots.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>

          <div>
            <div>
              <strong>Simulators:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowSimulators(!showSimulators)}>{showSimulators ? 'Hide' : 'Show'}</Button>
            {showSimulators && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.simulators.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>

          <div>
            <div>
              <strong>Task Manager:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowTaskManager(!showTaskManager)}>{showTaskManager ? 'Hide' : 'Show'}</Button>
            {showTaskManager && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.taskManager.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>

          <div>
            <div>
              <strong>Reports:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowReport(!showReport)}>{showReport ? 'Hide' : 'Show'}</Button>
            {showReport && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.report.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>

          <div>
            <div>
              <strong>Insigths:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowInsigths(!showInsigths)}>{showInsigths ? 'Hide' : 'Show'}</Button>
            {showInsigths && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.insights.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>

          <div>
            <div>
              <strong>Settings:</strong>
            </div>
            <Button basic className='helper_button' size='mini' onClick={() => setShowSettings(!showSettings)}>{showSettings ? 'Hide' : 'Show'}</Button>
            {showSettings && <div className='flex-wrap_fp' style={{marginTop: "5px"}}>
              {advisorGroup.insights.fields?.map((field, index2) => (
                <div key={index2}>
                  <Label>
                    {areasSimpleComplexNameMatch[field] || field}
                  </Label>
                </div>
              ))}
            </div>}
          </div>
          <div className='text_undelined' onClick={() => setShowAdvisors(!showAdvisors)}>Advisors ({advisorGroup.advisors?.length || 0})</div>
        </div>
      </div>

      <Modal open={showAdvisors} size='tiny'>
        <Modal.Header>
          <div className='flex-gap-5' style={{justifyContent: "space-between"}}>
            <div>
              Advisors ({advisorGroup.name})
            </div>
            <div>
              <Button basic className='helper_button' onClick={() => setShowAdvisors(!showAdvisors)} icon size="mini"><Icon name='close' /></Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          <ul>
            {advisorGroup.advisors?.map((advisor, index3) => (
              <li key={index3} style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/advisors/${advisor._id}`)}>
                {advisor.fullName} ({advisor.email})
              </li>
            ))}
          </ul>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CompanyAdvisorGroups;
