export const areasSimpleComplexNameMatch = {
  basictax: 'Basic Tax',
  auto: 'Auto Insurance',
  home: 'Home Insurance',
  disability: 'Disability Insurance',
  estate: 'Estate Planning',
  identity: 'Identity Theft',
  medical: 'Medical Insurance',
  student: 'Student Debt',
  general: 'General Questionnaire',
  fulltax: 'Tax Planning',
  ltc: 'Long Term Care',
  mortgage: 'Mortgage',
  life: 'Life Insurance',
  divorce: 'Divorce Planning',
  property: 'Property Tax',
  expense: 'Expense Analysis',
  elder: 'Elder Care',
  creditor: 'Creditor Protection',
  crossborder: 'Cross Border Planning',
  basicestate: 'Basic Estate',
  fulltaxnewengine: 'Tax New Engine',
  retirement: 'Retirement',
  investment: 'Investment',
};